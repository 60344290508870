<template>
  <v-row justify="center">
    <v-overlay :value="loading" z-index="2" color="black" dark>
      <v-progress-circular
        indeterminate
        size="64"
        color="white"
      ></v-progress-circular>
      <span :style="colorProgress">&nbsp; loading</span>
    </v-overlay>
    <v-dialog
      v-if="resolutionScreen >= 500"
      v-model="shower"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title
          >{{ $t("edithashtag.setting") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dark size="35"
            >edit</v-icon
          ></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text style="height:170px;">
          <!-- <v-checkbox
            v-model="checkhashtag"
            label="Add Hashtag"
          ></v-checkbox> -->

          <v-row>
            <v-col cols="10" sm="10">
              <v-text-field
                v-model="hashtag"
                label="#hashtag"
                outlined
                dense
                :error-messages="hashtagErrors"
                @input="$v.hashtag.$touch()"
                @blur="$v.hashtag.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2">
              <v-btn class="white--text" :color="color.theme" @click="addhashtag(), $v.hashtag.$reset()" :disabled="hashtagErrors.length > 0 ? true : false || hashtag.length === 0">
                {{ $t("edithashtag.add") }} </v-btn
              >
            </v-col>
            <v-col cols="12" class="mb-n12">
              <v-chip
                v-for="(item, index) in datahashtag_"
                :key="index"
                close
                class="ma-1"
                @click:close="deletehashtag(index)"
                >#{{ item }}</v-chip
              >
            </v-col>
          </v-row>
          <!-- <v-row v-else>
            <v-col cols="10" sm="10">
              <v-text-field v-model="hashtag" label="#hashtag" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="2" sm="2">
              <v-btn dark :color="color.theme" @click="addhashtag()"> {{ $t("edithashtag.add") }}</v-btn>
            </v-col>
            <v-col cols="12">
              <v-chip v-for="(item, index) in datahashtag_" :key="index" close class="ma-1" @click:close="deletehashtag(index)"
                >#{{ item }}</v-chip
              >
            </v-col>
          </v-row> -->
          <!-- <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Hashtag</v-list-item-title>
                 <v-list-item-subtitle><v-chip class="ma-1" v-for="item in filedata.hashtag" :key="item" >#{{item}}</v-chip></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list> -->

          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="white--text"
            outlined
            @click="$emit('close'), (datahashtag_ = []), checkbtn = 0, $v.hashtag.$reset(), hashtag = ''"
            :disabled="createprogress"
            >{{ $t("edithashtag.buttonclose") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            :loading="createprogress"
            class="white--text"
            @click="$emit('close'), edithashtag()"
            :disabled="checkbtn_add()"
            >{{ $t("edithashtag.ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="px-5 pt-4 pb-2">
          <v-layout class="pt-2 pb-1">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{ $t("edithashtag.setting") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('close'), (datahashtag_ = []), checkbtn = 0, $v.hashtag.$reset(), hashtag = ''"
                :disabled="createprogress"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text style="height: 200px; overflow-x: hidden;">
          <v-row row wrap class="">
            <v-col cols="9" sm="9">
              <v-text-field
                v-model="hashtag"
                label="#hashtag"
                outlined
                dense
                :error-messages="hashtagErrors"
                @input="$v.hashtag.$touch()"
                @blur="$v.hashtag.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2">
              <v-btn class="white--text" :color="color.theme" @click="addhashtag(), $v.hashtag.$reset()" :disabled="hashtagErrors.length > 0 ? true : false || hashtag.length === 0">
                {{ $t("edithashtag.add") }}</v-btn
              >
            </v-col>
            <v-col cols="12" class="mb-n12">
              <v-chip
                v-for="(item, index) in datahashtag_"
                :key="index"
                close
                class="ma-1"
                @click:close="deletehashtag(index)"
                >#{{ item }}</v-chip
              >
            </v-col>
          </v-row>
        </v-card-text>
        <div class="text-center my-4">
          <v-btn
            color="red"
            class="white--text mr-2"
            outlined
            @click="$emit('close'), (datahashtag_ = []), checkbtn = 0, $v.hashtag.$reset(), hashtag = ''"
            :disabled="createprogress"
            height="40px"
            width="40%"
            >{{ $t("edithashtag.buttonclose") }}</v-btn
          >
          <v-btn
            height="40px"
            width="40%"
            :color="color.theme"
            :loading="createprogress"
            class="white--text"
            @click="$emit('close'), edithashtag()"
            :disabled="checkbtn_add()"
            >{{ $t("edithashtag.ok") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <div class="text-center">
      <v-snackbar
        :color="$vuetify.theme.dark ? 'black' : 'white'"
        :style="`padding-top: ${i * 90}px`"
        v-for="(s, i) in listerror"
        :key="i"
        v-model="snackbarerror"
        :timeout="20000"
        top
        right
      >
        <v-list
          :style="
            $vuetify.theme.dark
              ? 'background-color: black; padding-top: 0px;'
              : 'background-color: white;padding-top: 0px;'
          "
        >
          <v-list-item
            dense
            style="height:40px; width:450px; color:red;"
            three-line
          >
            <v-list-item-icon>
              <v-icon class="mt-6" large color="red"
                >mdi-alert-octagon-outline</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                ><p style="color:black;" class="ma-0 mt-2">
                  {{ s.filename }}
                </p></v-list-item-title
              >
              <v-list-item-subtitle style="color:black;">{{
                "errorMessage : " + s.error
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn class="mt-2" text @click="closeMessageError(i)" fab small>
                <v-icon color="red">mdi-window-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import axios from "axios";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {},
  mixins: [validationMixin],
  validations: {
    hashtag: { required },
  },
  props: ["show", "filedata", "listcheckhashtag", "multihashtag"],
  data: function() {
    return {
      checkbtn: 0,
      auth: "",
      checkhashtag: "",
      hashtag: "",
      datahashtag_: [],
      createprogress: false,
      counterror: 0,
      snackbarerror: false,
      listerror: [],
      loading: false,
    };
  },
  // watch: {
  //   filedata: function(value) {
  //     console.log("watch value ",value);
  //     // if (value === false) {

  //     // } else {

  //     // }
  //   },
  // },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    colorProgress() {
      //return "color:" + this.color.theme + ";";
      return "color:" + "white" + ";";
    },
    shower: {
      get() {
        if (this.show === true) {
          // this.test();
          // console.log(this.listcheckhashtag);
          this.setdefaulthastag();
          // this.datahashtag_ = this.filedata.hashtag;
          console.log("datahashtag_", this.datahashtag_);
          this.listerror = [];
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    hashtagErrors() {
      let errors = [];
      if (this.$v.hashtag.$dirty === false) {
        return errors;
      } else if (this.$v.hashtag.required === false) {
        errors.push(this.$t("edithashtag.hashtagempty"));
        return errors;
      } else {
        let allow = true;
        let specialChar = [",", " ", "|"];

        for (let index = 0; index < specialChar.length; index++) {
          const element = specialChar[index];
          if (this.hashtag.includes(element)) {
            allow = false;
            break;
          } else {
            continue;
          }
        }
        if (allow === false) {
          errors.push(this.$t("edithashtag.nospecialcharacter"));
          return errors;
        } else {
          return errors;
        }
      }
    },
  },
  // watch: {
  //   datahashtag_(val) {
  //     console.log("watch", val);
  //   },
  // },
  methods: {
    checkbtn_add() {
      // console.log("listcheckhashtag", this.listcheckhashtag);
      // console.log("datahashtag_", this.datahashtag_);

      if (this.checkbtn == 1) {
        return false;
      } else {
        return true;
      }
    },
    // setdefaulthastag() {
    //   this.datahashtag_ = [];
    //   // this.datahashtag_ = this.listcheckhashtag;
    //   // this.datahashtag_ = this.filedata.hashtag;
    //   this.datahashtag_ = this.listcheckhashtag.hashtag;
    //   console.log("props listcheckhashtag", this.listcheckhashtag);
    // },
    setdefaulthastag() {
      console.log("this.multihashtag", this.multihashtag);
      // console.log("this.checkbtn", this.checkbtn);
      // console.log("this.listcheckhashtag.hashtag", this.listcheckhashtag["hashtag"]);
      if (this.multihashtag === undefined && this.checkbtn === 0) {
        for (let i = 0; i < this.listcheckhashtag["hashtag"].length; i++) {
          console.log("this.listcheckhashtag.hashtag[i]", this.listcheckhashtag["hashtag"][i]);
          this.datahashtag_.push(this.listcheckhashtag["hashtag"][i])
        }
      }
    },
    // reloadshowhashtag() {
    //   this.$emit("reload");
    // },
    deletehashtag(index) {
      this.checkbtn = 1;
      console.log("ก่อนลบ", index);
      this.datahashtag_.splice(index, 1);
      console.log("this.datahashtag_", this.datahashtag_);
    },
    addhashtag() {
      if (this.hashtag === "") {
        Toast.fire({
          icon: "warning",
          title: this.$t("edithashtag.hashtagempty"),
        });
      } else {
        this.checkbtn = 1;
        this.datahashtag_.push(this.hashtag);
        // console.log("this.datahashtag_", this.datahashtag_);
        this.hashtag = "";
      }
    },
    async edithashtag() {
      this.createprogress = true;
      this.checkbtn = 0;
      if (this.multihashtag) {
        try {
          this.loading = true;
          for (let i = 0; i < this.multihashtag.length; i++) {
            let newdatahashtag = this.datahashtag_;
            console.log("newdatahashtag", newdatahashtag);
            console.log("this.multihashtag[i][hashtag]",  this.multihashtag[i], this.multihashtag[i]['hashtag']);
            let payload = {
              data_id: this.multihashtag[i].file_id,
              data_type:
                this.multihashtag[i].file_type === "folder" ? "folder" : "file",
              account_id: this.dataAccountId,
              hashtag: this.multihashtag[i]["hashtag"].concat(newdatahashtag),
            };
            let auth = await gbfGenerate.generateToken();
            let resp = await axios.post(
              process.env.VUE_APP_SERVICE_UPDATE_MULTIPLE +
                "/api/update/hashtag",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            );
            try {
              if (resp.data.status === "OK") {
                // this.createprogress = false;
                // Toast.fire({
                //   icon: "success",
                //   title: this.$t("edithashtag.editsuccess"),
                // });
              } else {
                this.snackbarerror = true;
                let error = {
                  filename: this.multihashtag[i].file_name,
                  error: resp.data.errorMessage,
                };
                this.listerror.push(error);
              }
            } catch (err) {
              let error = {
                filename: this.multihashtag[i].file_name,
                error: resp.data.errorMessage,
              };
              this.listerror.push(error);
            }
            if (i + 1 === this.multihashtag.length) {
              this.datahashtag_ = [];
              this.$emit("reload");
              this.$emit("updatehashtag");
              this.$v.hashtag.$reset();
              this.createprogress = false;
              this.loading = false;
              Toast.fire({
                icon: "success",
                title: this.$t("edithashtag.editsuccess"),
              });
            } else {
              continue;
            }
          }
        } catch (err) {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: err,
          });
        }
      } else {
        let payload = {
          data_id: this.listcheckhashtag.id,
          data_type:
            this.listcheckhashtag.data_type === "folder" ? "folder" : "file",
          account_id: this.dataAccountId,
          hashtag: this.datahashtag_,
        };
        let auth = await gbfGenerate.generateToken();
        console.log("auth", auth);
        let resp = await axios.post(
          process.env.VUE_APP_SERVICE_UPDATE_MULTIPLE + "/api/update/hashtag",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        );
        console.log(resp);
        try {
          if (resp.data.status === "OK") {
            this.createprogress = false;
            Toast.fire({
              icon: "success",
              title: this.$t("edithashtag.editsuccess"),
            });
            this.$v.hashtag.$reset();
            this.datahashtag_ = [];
            this.$emit("reload");
            this.$emit("updatehashtag");
          } else {
            this.createprogress = false;
            Toast.fire({
              icon: "error",
              title: resp.data.errorMessage,
            });
          }
        } catch (err) {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: err,
          });
        }
      }

      // .then((response) => {
      //   console.log("Authorization",gbfGenerate.generateToken());
      //   if (response.data.status === "OK") {
      //     console.log("edithashtag", response);
      //     this.createprogress = false;
      //     Toast.fire({
      //       icon: "success",
      //       title: this.$t("edithashtag.editsuccess"),
      //     });
      //     this.$emit('reload')
      //   } else {
      //     this.createprogress = false;
      //     Toast.fire({
      //       icon: "error",
      //       title: response.data.errorMessage,
      //     });
      //   }
      // })
      // .catch((error) => {
      //   this.createprogress = false;
      //       console.log(error);
      //       Toast.fire({
      //         icon: "error",
      //         title: errorMessage,
      //       });
      //     });
    },
    closeMessageError(i) {
      this.listerror.splice(i, 1);
    },
  },
};
</script>
<style></style>
